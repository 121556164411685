import React from "react";
import bgDashboard from "../Assets/bg.jpg";
import Invalidchain from "../Components/others/InvalidChain";
import AppRouter from "./AppRouter";
import Header from "./Header";
import Footer from "./Footer";
import ConnectWallet from "../Components/layout/ConnectWallet";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";

export default function Layout() {  

    const account = useAccount();

    return (
        <>  
            <Invalidchain />
            <div className="bg-red-400 min-h-screen relative px-4 py-10">
                <div className="w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center z-0"
                    style={{ backgroundImage: `url(${bgDashboard})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}
                ></div>
                <div className="relative z-10">
                    <Header />
                    <main className="p-5 px-1 sm:p-10">
                        <div className=" bg-black/60 max-w-[1000px] rounded-3xl mx-auto shadow-lg p-5">
                            <ConnectWallet />
                            {account.isConnected &&
                                <AppRouter />
                            }
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    );
}