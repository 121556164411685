import React, { useEffect, useState } from "react";
import { createPublicClient, http, parseAbiItem } from "viem";
import { bsc, bscTestnet } from "viem/chains";
import { useAccount } from "wagmi";
import appConfig from "../../Utility/Config/config";
import AppTable from "../../Utility/AppTable";
import { EmptyRecords, LoadingData } from "../../Utility/Utility";
import { useCallAPI } from "../../Hooks/callAPI";
import toast from "react-hot-toast";
import Timestamp from "react-timestamp";
import { useHelpers } from "../../Hooks/helpers";

const MyReferrals = () => {

  const account = useAccount();
  const helper = useHelpers();
  const callAPI = useCallAPI();
  const [refs, setRefs] = useState();

  useEffect(() => {
    getReferrals();
  }, [account.address]);

  const getReferrals = async () => {

    try {
      
      const res = await callAPI('getreferrals/' + account.address);
      console.log(res);
      if(res.state !== true) throw res;
      setRefs(res.data);

    } catch (error) {
      console.log(error);
      toast.error('Failed to get referrals');
    }
  }

  let headings = [
    'Referral', 'Amount', 'Hash', 'Date'
  ];

  return (
    <div className="mt-8">
      <h3 className="text-center text-white text-xl font-bold mt-5 mb-5">My Referrals</h3>
      {refs && refs.length > 0 ?
        <div className="bg-white rounded-xl p-2">
          <table className="w-full min-w-max table-auto text-left font-oxanium">
            {headings && 
              <thead>
                <tr className="bg-primary text-sm font-bold text-black">
                  {headings.map((head) => (
                    <th
                      key={head}
                      className="p-4 first-of-type:rounded-l-lg last-of-type:rounded-r-lg"
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
            }
            <tbody>
              {refs.map((e, index) => {
                return (
                  <tr key={index}>
                    <td className='p-3 border-b border-gray-100 text-sm'><helper.trimAddress address={e.referralAddress} copy /></td>
                    <td className='p-3 border-b border-gray-100 text-sm'>20 {appConfig.tokenSymbol}</td>
                    <td className='p-3 border-b border-gray-100 text-sm'><helper.trimAddress address={e.hash} copy /></td>
                    <td className='p-3 border-b border-gray-100 text-sm'><Timestamp date={e.created} /></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      :
        <EmptyRecords title='No Referrals Found' description='Your referrals will show here' />
      }
    </div>
  );
};
export default MyReferrals;
