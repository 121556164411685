import { Button } from "@material-tailwind/react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useDisconnect } from "wagmi";
import { useHelpers } from "../../Hooks/helpers";

const ConnectWallet = () => {
  
  const helper = useHelpers();
  const web3Modal = useWeb3Modal();
  const disconnect = useDisconnect();
  const account = useAccount();

  return (
      <div className="text-center font-oxanium text-black mt-5">
        <h3 className="text-3xl text-white txt_shadow_one">Hey, Welcome to <br/><b>TheNewForce Army</b></h3>

        {account.isConnected ?
          <div className="flex justify-between align-middle items-center max-w-[400px] mx-auto shadow-lg bg-white/80 rounded-lg mt-5 px-4 py-2">
              <div className="text-left text-sm">
                Connected Wallet
                <strong className="block text-base leading-tight font-extrabold relative z-0 cursor-pointer">
                  <span onClick={web3Modal.open}><helper.trimAddress address={account.address} /></span>
                </strong>
              </div>
              <div className="">
                <Button size="sm" color="red" onClick={disconnect.disconnect}>Disconnect</Button>
              </div>
          </div>
        :
          <div className="text-center max-w-[400px] mx-auto shadow-lg bg-white/80 rounded-lg mt-5 px-4 py-5 mb-5">
            <h3 className="text-xl font-bold mb-3 text-black/80">Let's connect your wallet first</h3>
            <div className="flex justify-center">
              <Button color="green" size="lg" disabled={web3Modal.isOpen} loading={web3Modal.isOpen} onClick={web3Modal.open}>Connect Wallet</Button>
            </div>
          </div>
        }
      </div>
  );
};
export default ConnectWallet;
