import appConfig from "../Utility/Config/config";

const Footer = () => {
  return (
    <div className="text-center text-sm">
      <span className="bg-black/50 px-3 py-2 rounded-md shadow-md text-white/70">&copy; thenewforce.io | v{appConfig.version}</span>
    </div>
  );
};
export default Footer;
