import React from "react";
import { useAccount } from "wagmi";
import appConfig from "../../Utility/Config/config";
import { useHelpers } from "../../Hooks/helpers";
import { Button } from "@material-tailwind/react";
import MyReferrals from "./MyReferrals";

const Refer = () => {

  const account = useAccount();
  const helper = useHelpers();

  return (
    <>
      <div className="text-center font-oxanium mt-6 pt-6 border-t-[1px] border-gray-400/30 mb-3 text-white text-md max-w-lg mx-auto">
        Congratulations! You can get more tokens by sharing {appConfig.tokenName} to your friends and family.
        <br/><b className="text-xl text-primary">You will earn 20 {appConfig.tokenSymbol} when your referral claims their first airdrop.</b>
      </div>
      <div className="text-center">
        <h3 className="text-center font-oxanium mt-8 mb-3 text-white text-md max-w-lg mx-auto">Your Unique Invitation Link</h3>
        <div className="bg-white/80 text-black inline-block mx-auto text-sm font-bold px-5 py-2 rounded-md"><helper.copyValue value={appConfig.appLink + 'r/' + account.address} /></div>
      </div>
      <MyReferrals />
    </>
  );
};
export default Refer;
