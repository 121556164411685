import { signMessage, waitForTransaction, writeContract } from '@wagmi/core';
import toast from 'react-hot-toast';
import mainAbi from '../Utility/ABI/main.json';
import appConfig from '../Utility/Config/config';
import { useHelpers } from "./helpers";
import { usePendingModal } from "./pendingModal";

export const useWriteContract = () => {

    const [modalShow, modalData, showModal, hideModal] = usePendingModal();
    
    const ContractWrite = async ({ writeFn, inputData, successCallback, value = 0 }) => {

        const loadingToast = toast.loading('Approve transaction from your wallet');

        const validateTxn = async (hash) => {
            const processingTxn = toast.loading('Confirming. Please wait');
            await waitForTransaction({ hash: hash,  onReplaced: (newTxn) => console.log(newTxn) });
            await successCallback(hash);
            toast.dismiss(processingTxn);
            toast.success('Transaction Completed');
        }

        await writeContract({
            address: appConfig.contractAddr,
            abi: mainAbi,
            functionName: writeFn,
            args: inputData,
            value: value === 0 ? undefined : value
        }).then(async (res) => {
            toast.dismiss(loadingToast);
            await validateTxn(res.hash);
        }).catch(async (e) => {
            console.log(e);
            toast.dismiss(loadingToast);
            hideModal();
            toast.error(e.message);
        });

    }

    const SignMessage = async (message, successCallback) => {
        
        const loadingToast = toast.loading('Sign message from your wallet app to continue');

        await signMessage({message: message}).then(async (res) => {
            toast.dismiss(loadingToast);
            await successCallback(res);
        }).catch(async (e) => {
            toast.dismiss(loadingToast);
            // const errData = ProcessWriteError(e);
            toast.error(e.name);
        });

    }

    return {ContractWrite, SignMessage, modalShow, modalData};
}

export const ProcessWriteError = error => {

    const helpers = useHelpers();
    console.log(error);

    //If the string has a 'reason'
    if(error.reason != undefined) {
        return { status: false, message: helpers.capitalize(error.reason) };
    }

    if(error.code != undefined) {
        switch (error.code) {
            case 'ACTION_REJECTED':
                errorMsg = 'Transaction rejected by user';
                break;
        
            default:
                errorMsg = 'Error: ' + error.code + ' | Contact support for more info';
                break;
        }
        return {status: false, message: errorMsg};
    }
    
    return {status: false, message: 'Transaction error'};
}