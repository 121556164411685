import { fetchBalance, readContract as readWagmiContract } from '@wagmi/core';
import { formatEther } from 'viem';
import { useAccount } from 'wagmi';
import contractAbi from '../Utility/ABI/main.json';
import appConfig from '../Utility/Config/config';

export const useReadContract = () => {

    const account = useAccount();

    const contractObj = async (contractAddr, abi, methodName, methodParams) => {
        const data = await readWagmiContract({
            address: contractAddr,
            abi: abi,
            functionName: methodName,
            args: methodParams,
        });
        return data;
    }

    const read = async (method, params) => {
        const res = await contractObj(appConfig.contractAddr, contractAbi, method, params);
        return res;
    }
    
    return {read};
}