import axios from 'axios';
import toast from 'react-hot-toast';
import appConfig from '../Utility/Config/config';

export const useCallAPI = () => {

    const getResults = async (callFn, data = {}) => {

        try {
            const headers = {
                'Content-Type': 'application/json',
            }
            const res = await axios.get(appConfig.apiEndpoint + callFn, data, { headers: headers });
            return res.data;
        } catch (error) {
            console.log(error);
            error.response?.data?.error?.message ? toast.error(error.response.data.error.message) : toast.error('Internal Server Error');
            return (error.response ? error.response.data.error : error);
        }
        
    }

    return getResults;

};