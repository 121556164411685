import { Button } from "@material-tailwind/react";
import { fetchBalance } from '@wagmi/core';
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { formatEther } from "viem";
import { useAccount } from "wagmi";
import { useHelpers } from "../../Hooks/helpers";
import { useReadContract } from "../../Hooks/readContract";
import { useWriteContract } from "../../Hooks/writeContract";
import appConfig from "../../Utility/Config/config";
import Countdown from "react-countdown";
import { Clock } from "react-feather";

const ClaimTokens = ({ checkClaimed }) => {

  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const helper = useHelpers();
  const account = useAccount();

  const [loading, setLoading] = useState(false);
  const [referrer, setReferrer] = useState(false);
  const [refTokens, setRefTokens] = useState(false);
  const [userData, setUserData] = useState(false);
  const [claimCooldown, setClaimCooldown] = useState(0);
  
  useEffect(() => {
    getData();
  }, []);
  
  const getData = async () => {
    setUserData(await readContract.read('users', [ account.address ]));
    setClaimCooldown(((await readContract.read('CLAIM_COOLDOWN'))).toString()*1);

    let referrer = localStorage.getItem('referrerAddress');
    if(!referrer) {
      referrer = await readContract.read('adminAddress');
    }
    setReferrer(referrer);
    const refTokensRes = await readContract.read('TOKENS_TO_REFERRER');
    setRefTokens(formatEther(refTokensRes).toString());
  }

  const claimAirdrop = async () => {

    setLoading(true);
    const fee = await readContract.read('FEE');
    const cost = await readContract.read('getCost', [ fee.toString() ]);
    const balanceRes = await fetchBalance({ address: account.address });

    if(balanceRes.formatted*1 < formatEther(cost)*1) {
      toast.error('Insufficient balance');
      setLoading(false);
      return;
    }

    await writeContract.ContractWrite({ writeFn: 'ClaimAirdrop', inputData: [ referrer ], successCallback: success, value: cost.toString() });
    setLoading(false);

  }

  const success = async (hash) => {
    console.log(hash);
    await checkClaimed();
    await getData();
  }

  return (
    <>
    {userData &&
      <>
        {userData[3].toString()*1 === 0 ?
          <div className="text-center font-oxanium mt-8 mb-3 text-white text-xl">
            Congratulations! You are now qualified to claim ${appConfig.tokenSymbol} tokens
          </div>
        :
          <div className="flex justify-center items-center align-middle gap-5 my-5">
            <div className="border-primary border-2 rounded-xl px-7 text-center py-2">
              <h3 className="font-bold text-xl text-primary">{formatEther(userData[1])} {appConfig.tokenSymbol}</h3>
              <p className="text-gray-300 text-sm">Tokens available</p>
            </div>
            <div className="border-primary border-2 rounded-xl px-7 text-center py-2">
              <h3 className="font-bold text-xl text-primary">{formatEther(userData[3])} {appConfig.tokenSymbol}</h3>
              <p className="text-gray-300 text-sm">Tokens claimed</p>
            </div>
          </div>
        }
        <div className="flex items-center align-middle justify-center">
          <Button size="lg" color="amber" onClick={claimAirdrop} loading={loading} disabled={loading || userData[2].toString()*1 + claimCooldown > Date.now()/1000}>Claim Airdrop</Button>
        </div>
        {userData[2].toString()*1 + claimCooldown > Date.now()/1000 && <p className="text-center text-white text-sm mt-3 flex justify-center gap-2 align-middle items-center"><Clock size={18} className="animate-spin" />Claim again in <Countdown onComplete={getData} date={(claimCooldown + userData[2].toString()*1)*1000} daysInHours={true} renderer={({ hours, minutes, seconds }) => { return <span>{hours} : {minutes} : {seconds}</span> }} /></p>}
        {userData[3].toString()*1 === 0 && referrer && <p className="text-center text-white text-sm mt-3">Your referrer <b><helper.trimAddress address={referrer} copy /></b> will earn {refTokens} {appConfig.tokenSymbol} as bonus</p>}
      </>
    }
    </>
  );
};
export default ClaimTokens;
