import React from "react";
import appConfig from "../../Utility/Config/config";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useNetwork } from "wagmi";
const Invalidchain = () => {

    const network = useNetwork();
    const account = useAccount();

    return (
        <>
            {account.isConnected && network.chain?.id !== appConfig.supportedChain*1 &&
                <div className="absolute w-full z-10 bg-red-500 top-0 shadow-lg text-center p-2 text-white font-semibold text-sm">
                    Invalid Chain. Connect to Binance Smart Chain.
                </div>
            }
        </>
    );
};

export default Invalidchain;