import CopyToClipboard from 'react-copy-to-clipboard';
import { Copy, ExternalLink } from 'react-feather';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';

export const useHelpers = () => {

    //Trim an address
    const trimAddress = ({ address, copy = false, scanURL = false, firstShow = 5, secondShow = 3 }) => {
        
        if(address == null) {
            return (<></>);
        }
        const fullAddress = address;

        const short1  = fullAddress.slice(0, firstShow);
        const short2  = fullAddress.slice(fullAddress.length-secondShow, fullAddress.length);
        const trimmedAddr = short1 + '...' + short2;

        if(copy === false) {
            return trimmedAddr;
        } else {
            return (
                <>
                    <span>{trimmedAddr}</span>
                    {copy && <CopyToClipboard onCopy={() => toast.success('Copied!')} text={address}><Copy className='inline-block ml-1' size="13" /></CopyToClipboard>}
                    {scanURL && <a target="_blank" href={scanURL}><ExternalLink className='inline-block ml-1' size="13" /></a>}
                </>
            )
        }
        
    }

    //Convert a amount to readable format
    const AmountToCurrency = ({ amount, decimals = 4, prefix = "$" }) => {
        
        return (
            <span>
                <NumericFormat value={amount} displayType="text" allowLeadingZeros allowNegative={true} prefix={prefix} thousandsGroupStyle="thousand" decimalScale={decimals} />
            </span>
        );
    }

    //Whole Number
    const renderNumber = ({ amount, decimals = 2, prefix = '', suffix = '' }) => {
        return (
            <NumericFormat value={amount} displayType="text" allowLeadingZeros allowNegative={true} prefix={prefix} suffix={suffix} decimalScale={decimals} />
        );
    }
    
    //Convert timestamp to data
    const ProcessDate = (timestamp, opString = false ) => {
        const date = new Date(timestamp*1000);
        const humanData = +date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();

        if(opString) {
            return humanData;
        } else {
            return (
                <>
                {humanData}
                </>
            )
        }
    }

    //Convert timestamp to data by format
    const ProcessDateByFormat = (timestamp, opString = false ) => {
        const humanData = new Date(timestamp*1000).toString().substring(4, 15);
        
        if(opString) {
            return humanData;
        } else {
            return (
                <>
                {humanData}
                </>
            )
        }
    }

    //Round to precision
    const RoundToPrecision = function(input, precision = 5) {
        
        if (typeof input !== 'number') {
            input = input*1;
        }
        if (input === 0) return 0;
        var abs = Math.abs(input);
        var sign = input / abs;
        input = abs;
        var digits = Math.ceil(Math.log(input)/Math.LN10);
        var factor = Math.pow(10, precision - digits);
        var result = input * factor;
        result = Math.round(result, 0);
        return result / factor;
    };

    const toCurrency = (val) => {
        return "$" + val;
    }

    const capitalize = (val) => {
        let result = val.replace(/([A-Z])/g, " $1");
        result = result.replace(/([0-9])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

    const processType = (val) => {
        switch (val) {
            case "direct":
                return (<div className='btn btn-success btn-sm'>{capitalize(val)}</div>)
                break;
            case "missed":
                return (<div className='btn btn-danger btn-sm'>{capitalize(val)}</div>)
                break;
            case "gift":
                return (<div className='btn btn-info btn-sm'>{capitalize(val)}</div>)
                break;
            case "fundResidual":
                return (<div className='btn btn-warning btn-sm'>{capitalize(val)}</div>)
                break;
            case "residual":
                return (<div className='btn btn-info btn-sm'>{capitalize(val)}</div>)
                break;
            case "checkmatch":
                return (<div className='btn btn-dark btn-sm'>{capitalize(val)}</div>)
                break;
                                
            default:
                return (<div className='btn btn-warning btn-sm'>{capitalize(val)}</div>)
                break;
        }
    }

    const groupObjArrayByKey = (list, key) => {
        return list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {});
    }

    const renderStatusForButton = ({ status }) => {
        let renderButtonObj = {
            classes: '',
            text: ''
        };
        switch (status) {
            //Campaign status
            case "active":
                renderButtonObj = {
                    color: 'green',
                    classes: 'bg-green-600 text-white',
                    text: capitalizeString({ text: status })
                }
                break;
            case "underReview":
                renderButtonObj = {
                    color: 'black',
                    classes: 'bg-gray-900 text-white',
                    text: capitalizeString({ text: status })
                }
                break;
            case "paused":
                renderButtonObj = {
                    color: 'gray',
                    classes: 'bg-yellow-300 text-gray-800',
                    text: capitalizeString({ text: status })
                }
                break;
            case "rejected":
                renderButtonObj = {
                    color: 'red',
                    classes: 'bg-red-500 text-white',
                    text: capitalizeString({ text: status })
                }
                break;
            
            default:
                renderButtonObj = {
                    classes: 'bg-gray-200 text-gray-900',
                    text: capitalizeString({ text: status })
                }
                break;

        }
        return (
            renderButtonObj
        );
    }

    const capitalizeString = ({ text }) => {
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }


    //CopyString
    const copyValue = ({ value = false, iconSize = '13' }) => {
        
        if(value == null) {
            return (<></>);
        }
        if(value === false) {
            return value;
        } else {
            return (
                <span className='flex items-center align-middle'>
                    {value}
                    {<CopyToClipboard onCopy={() => toast.success('Copied!')} text={value}><Copy className='cursor-pointer ml-1 hover:opacity-100' size={iconSize} /></CopyToClipboard>}
                </span>
            )
        }
        
    }


    return {capitalizeString, copyValue, renderStatusForButton, renderNumber, trimAddress, ProcessDate, AmountToCurrency, ProcessDateByFormat, toCurrency, capitalize, processType, groupObjArrayByKey};
    
}

