const runDevAsProd = true;
const settings = {
    testNet: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false, //Change to false for production
    useDevServer: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false //Change to false for using production servers
}

const appConfig = {
    version: '1.0.4',
    appLink: 'https://app.thenewforce.io/',
    websiteLink: 'https://app.thenewforce.io',
    testnet: settings.testNet,
    walletConnectKey: '6d3b6c3978f2d125f03bf86e6ba68425',
    customRPCURL: settings.testNet?'https://bold-misty-thunder.bsc-testnet.quiknode.pro/2e47f29249ec2044358b04e737e2438b16a753ea/':'https://attentive-black-field.bsc.quiknode.pro/c7452f3001cd9fb2edf6c525b50404c26b7b7607/', //admin@spicydevs.com
    apiEndpoint: settings.useDevServer?'http://localhost:5583/api/':'https://api.thenewforce.io/api/',
    supportedChain: settings.testNet?'97':'56',
    supportedChainName: settings.testNet?'Binance Smart Chain Testnet':'Binance Smart Chain Mainnet',
    scanURL: settings.testNet?'https://testnet.bscscan.com/':'https://bscscan.com/',
    gaCode: "G-CTDVL8W4LS",

    //Project specific details
    contractAddr: '0x5eDdc5df0edB2f39440f0CBBb3c9fC9628B8C586',
    tokenName: 'TheNewForce',
    tokenSymbol: 'TNF',
    totalTasks: 2,
    projectLaunchBlock: 35901167n,
    
    //URLs
    twitter: 'https://twitter.com/TheNewForce_mt',
    telegram: 'https://t.me/thenewforce_memetoken',
}

export default appConfig;