import { Button } from "@material-tailwind/react";
import React from "react";
import tg from "../../Assets/telegram.png";
import twitter from "../../Assets/twitter.png";
import appConfig from "../../Utility/Config/config";

const CompleteTasks = ({ setQualification }) => {

  const qualifyRank = () => {
    if(localStorage.getItem('qualification')) {
      localStorage.setItem('qualification', localStorage.getItem('qualification')*1 + 1);
    } else {
      localStorage.setItem('qualification', 1);
    }
    setQualification(localStorage.getItem('qualification'));
  }

  return (
    <div className="mb-8">
        
        <h3 className="text-center font-oxanium my-8 text-white text-xl">Complete simple steps and<br/><span className="text-2xl font-bold">Claim ${appConfig.tokenSymbol} tokens</span></h3>
        
        <div className="md:flex items-center align-top justify-center gap-7 ">
            
            <div className="text-center bg-white/90 rounded-xl p-5 pt-8 relative max-w-[300px] mx-auto md:mx-0 mb-7 md:mb-0">
            <div className="absolute -left-3 -top-3 shadow-md bg-primary rounded-full text-center leading-[35px] font-oxanium text-2xl font-bold w-[35px] h-[35px]">1</div>
            <img src={tg} className="w-[70px] mx-auto" />
            <h3 className="font-bold mt-5">Join Official Telegram</h3>
            <p className="text-sm leading-4 mt-2 mb-4 text-gray-800">Open TheNewForce's official telegram chat and join to qualify.</p>
            <a href={appConfig.telegram} target="blank"><Button color="indigo" size="lg" onClick={qualifyRank}>Open in Telegram</Button></a>
            </div>

            <div className="text-center bg-white/90 rounded-xl p-5 pt-8 relative max-w-[300px] mx-auto md:mx-0">
            <div className="absolute -left-3 -top-3 shadow-md bg-primary rounded-full text-center leading-[35px] font-oxanium text-2xl font-bold w-[35px] h-[35px]">2</div>
            <img src={twitter} className="w-[70px] mx-auto" />
            <h3 className="font-bold mt-5">Join Official X</h3>
            <p className="text-sm leading-4 mt-2 mb-4 text-gray-800">Open TheNewForce's official X and join to qualify.</p>
            <a href={appConfig.twitter} target="blank"><Button color="black" size="lg" onClick={qualifyRank}>Open in X</Button></a>
            </div>

        </div>
      
    </div>
  );
};

export default CompleteTasks;
