import React from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import Dashboard from "../Pages/Dashboard";

export default function AppRouter() {
  return (
    <Routes>
        {/* Dashboard Routes */}
        <Route exact path="/" element={<Dashboard />}></Route>
        {/* <Route path="/referrals" element={<Referrals />}></Route>
        <Route path="/tgAlerts" element={<TelegramAlerts />}></Route> */}
        <Route path="/r/:address" element={<Dashboard />}></Route>
    </Routes>
  );
}