import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import ClaimTokens from "../Components/dashboard/ClaimTokens";
import CompleteTasks from "../Components/dashboard/CompleteTasks";
import Refer from "../Components/dashboard/Refer";
import { useReadContract } from "../Hooks/readContract";
import appConfig from "../Utility/Config/config";
import { LoadingData } from "../Utility/Utility";

const Dashboard = () => {

  const params = useParams();
  const readContract = useReadContract();
  const account = useAccount();

  const [claimed, setClaimed] = useState(null);
  const [qualification, setQualification] = useState(localStorage.getItem('qualification') ? localStorage.getItem('qualification') : 0);

  useEffect(() => {
      if(params.address) {
          localStorage.setItem("referrerAddress", params.address);
      }
  }, []);

  useEffect(() => {
      checkClaimed();
  }, [account]);

  //Check if the user has already claimed
  const checkClaimed = async () => {
    const res = await readContract.read('users', [ account.address ]);
    if(res && res[3].toString()*1 > 0) {
      setClaimed(true);
    } else {
      setClaimed(false);
    }
  }

  return (
    <>
      {claimed !== null ? 
        <>
          {!claimed && <CompleteTasks setQualification={setQualification} />}
          {qualification*1 >= appConfig.totalTasks &&
            <ClaimTokens checkClaimed={checkClaimed} />
          }
          {claimed && <Refer />}
        </>
      :
        <LoadingData />
      }
    </>
  );
};

export default Dashboard;
