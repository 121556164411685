import React from "react";
import logo from "../Assets/logo3@0.5x.png";

export default function Header() {  
    
    return (
        <>  
            <div className="">
                <img src={logo} className="w-[150px] mx-auto" />
            </div>
        </>
    );
}