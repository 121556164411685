import { setupIonicReact } from '@ionic/react';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { publicProvider } from '@wagmi/core/providers/public';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from "react-router-dom";
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { bsc, bscTestnet } from 'wagmi/chains';
import ScrollToTop from './Contexts/ScrollToTop';
import { AppStateProvider } from './Contexts/appStateContext';
import Layout from './Layout/Layout';
import appConfig from './Utility/Config/config';

setupIonicReact();

function App() {
  const chains = [ appConfig.testnet ? bscTestnet : bsc ];
  const projectId = appConfig.walletConnectKey;
  const [loadWeb3Modal, setLoadWeb3Modal] = useState(false);

  let allProviders = [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: appConfig.customRPCURL,
      }),
    }), 
    w3mProvider({ projectId }), 
    publicProvider()
  ];

  const { publicClient } = configureChains(chains, allProviders);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
  })
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  useEffect(() => {
    setLoadWeb3Modal(true);
  }, [])
  return (
    <>
      <AppStateProvider>
        {loadWeb3Modal && 
          <WagmiConfig config={wagmiConfig}>
            <Router>
              <ScrollToTop />
              <Layout />
            </Router>
            <Toaster position="top-right" toastOptions={{
              className: '',
              style: {
                border: '1px solid #111',
                background: 'rgba(0,0,0,0.8)',
                color: '#fff',
              },
            }}/>
          </WagmiConfig>
        }
      </AppStateProvider>
      {/* <Translator /> */}
      <Web3Modal projectId={projectId} defaultChain={appConfig.testnet ? bscTestnet : bsc} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
